.eh-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;

  .modal-content {
    display: flex;
    flex-direction: column;
    background: var(--primary-bg-color) !important;
    border-radius: 8px;
    width: 35%;
    height: 50%;
    left: 100px;
    justify-content: center;
    min-width: 400px;

    /* .close {
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 30px;
            cursor: pointer;
            color: #fff;
        } */

    .top {
      display: flex;
      flex-direction: column;
      justify-content: center !important;
      align-items: center;
      width: 100%;
      gap: 20px;

      @media screen and (max-width: 1200px) {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
      }

      .filter-menu-list-empUpload {
        display: flex;
        justify-content: center;
        color: var(--black-color);
        border-radius: 25px;
        width: 100%;
        text-align: center;
        gap: 40px;

        @media screen and (max-width: 1200px) {
          flex-direction: column;
        }

        .filter-menu-empUpload {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 10px;
          border-radius: 8px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          width: 10 0%;

          .filter-datemenu-empUpload {
            display: flex;
            align-items: center;
            width: 100%;

            @media screen and (max-width: 660px) {
              display: flex;
              flex-direction: column;
              width: 100%;

              .filter-datemenu-select-empUpload {
                width: 100%;
                margin: 10px 0;
              }

              label {
                width: 100%;
              }
            }

            label {
              margin-right: 10px;
              font-size: 17px;
              margin-left: 10px;
              font-weight: bold;
            }
          }

          label {
            margin-right: 5px;
            font-size: 13px;
            margin-top: 10px;
            text-align: center;
          }

          .labelFilter {
            margin-left: 10px;
            font-weight: bold;
            color: var(--secondary-bg-color);
            background-color: var(--primary-bg-color);
            padding-top: 7px;
            text-align: center;
            margin-top: 0px;
          }

          select {
            padding: 5px;
            border: none;
          }
        }
      }

      .block {
        display: flex;
        flex-direction: column;
        background-color: var(--lightgray-color);
        border-radius: 8px;
        gap: 30px;
        padding: 20px;

        .top-block {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;

          h4 {
            font-weight: bold;
            font-size: x-large;
          }

          span {
            font-size: medium;
          }
        }

        .bottom-block {
          display: flex;
          font-size: medium;
          margin-left: 20px;
        }
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 25px;
      gap: 40px;

      .sub-btn {
        color: var(--primary-bg-color);
        font-size: 13px;
        padding: 10px 0px;
        margin-right: 5px;
        border-radius: 8px;
        display: inline-block;
        border: 0;
        outline: 0;
        background-color: var(--secondary-bg-color);
        text-decoration: none;
        width: 6rem;
        text-align: center;

        &:hover {
          background-color: var(--black-color);
        }
      }
    }
  }
}
