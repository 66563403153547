body {
  scroll-behavior: smooth;
}

.main-layout {
  display: flex;
  position: relative;
  height: 100vh;
  overflow: hidden;

  .layout-sidebar {
    @media screen and (max-width: 768px) {
      display: none;
    }
    .close-btn {
      display: none;
    }
  }

  .layout-sidebar.display {
    @media screen and (max-width: 768px) {
      display: block !important;
      position: fixed;
      z-index: 100;
      height: 100vh;
      width: 100%;
      top: 0px;
      left: 0px;
      background-color: #00000070;
    }

    .close-btn {
      display: none;

      @media screen and (max-width: 768px) {
        display: block;
        font-size: 18px;
        position: fixed !important;
        left: 220px;
        top: 20px;
        color: var(--primary-bg-color);
      }
    }
  }


  .page-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding: 0px 0px 20px;
    overflow-y: scroll;
    position: relative;

    .hamburger {
      display: none;

      @media screen and (max-width : 768px) {
        display: block;
        position: fixed;
        font-size: 20px;
        left: 33px;
        top: 52px;
        line-height: 0px;
        background-color: transparent !important;
        color: var(--primary-bg-color);
      }
    }

    .nav {
      position: sticky;
      top: 0px;
      background-color: white;
      padding: 20px 20px 0px;
      z-index: 19;
    }

    .page-wrapper {
      margin-top: 0px;
      padding: 0px 20px;
      // z-index: 0;
    }

  }

}