.block {
  text-decoration: none !important;
  background-color: var(--lightgray-color);
  border-radius: 8px;

  .block-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;

    .block-title {
      padding: 10px;

      span {
        font-size: 20px;
        font-weight: 700;
        color: var(--black-color);
      }
    }

    .block-select {
      padding: 10px;

      select {
        padding: 5px;
        border: none;
      }
    }

    .block-export-button {
      width: 55%;
      display: flex;
      justify-content: flex-end;

      .edit-btn {
        color: var(--primary-bg-color);
        font-size: 13px;
        padding: 5px 0px;
        margin-right: 5px;
        border-radius: 5px;
        display: inline-block;
        border: 0;
        outline: 0;
        background-color: var(--secondary-bg-color);
        text-decoration: none;
        width: 5rem;
        text-align: center;

        &:hover {
          background-color: var(--black-color);
        }
      }
    }
  }

  .bar-chart {
    display: flex;
    align-items: center;
    width: 100%;
    height: 170px;
    margin-top: 20px;

    .chart {
      flex: 1;
    }

    .label {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      span {
        font-weight: bold;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        margin-top: 20px;

        li {
          font-size: 12px;
          margin-bottom: 10px;

          .bullet {
            display: inline-block;
            width: 6px;
            /* Adjust width as needed */
            height: 6px;
            /* Adjust height as needed */
            border-radius: 50%;
            margin-right: 5px;

            /* Adjust spacing as needed */
          }
        }
      }
    }

    // recharts custom
    /* .recharts-legend-item {
            align-items: center !important;
        } */
  }
}
