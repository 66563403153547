.euc-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;

  .modal-content {
    display: flex;
    flex-direction: column;
    background: var(--primary-bg-color) !important;
    border-radius: 25px;
    width: 60%;
    height: auto;
    padding: 20px;
    min-width: 400px;

    .close-button {
      display: flex;
      justify-content: flex-end;

      button {
        background: none;
        border: none;
        font-size: 1rem;
        cursor: pointer;
        padding-right: 20px;
        padding-top: 7px;
      }
    }

    .top {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      color: var(--black-color);
      margin-top: 20px;
      width: 100%;
      flex-wrap: wrap;
      gap: 20px;

      @media screen and (max-width: 1024px) {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        /* flex-direction: column; */
      }

      .block {
        display: flex;
        flex-direction: column;

        background-color: var(--lightgray-color);
        border-radius: 25px;
        gap: 30px;
        padding: 20px;

        .top-block {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;

          h4 {
            font-weight: bold;
            font-size: x-large;
          }

          span {
            font-size: medium;
          }
        }

        .bottom-block {
          display: flex;
          font-size: medium;
          margin-left: 20px;
        }
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 25px;

      .sub-btn {
        color: var(--primary-bg-color);
        font-size: 13px;
        padding: 10px 0px;
        margin-right: 5px;
        border-radius: 25px;
        display: inline-block;
        border: 0;
        outline: 0;
        background-color: var(--secondary-bg-color);
        text-decoration: none;
        width: 6rem;
        text-align: center;

        &:hover {
          background-color: var(--black-color);
        }
      }
    }
  }
}
