.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;

  .modal-content {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    background: var(--primary-bg-color);
    border-radius: 0px !important;
    padding: 0px !important;

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 50px;
      cursor: pointer;
      color: #656565 !important;

      &:hover {
        color: #000000 !important;
      }
    }

    .bottom {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      background-color: var(--lightgray-color);
      border-radius: 8px;

      .add-revenue-and-expenses {
        width: 100%;
        padding: 10px;

        h2 {
          text-align: center;
          margin-bottom: 20px;
        }

        form {
          .store-month-year {
            display: flex;
            margin-bottom: 10px;

            label {
              width: calc(33.33% - 10px);

              input,
              select {
                margin-left: 10px;
                padding: 3px;
                border: 1px solid var(--lightgray-color);
                border-radius: 8px;
                text-align: center;
              }
            }
          }

          .dataEnryOptions {
            display: flex;

            .radioOptions {
              display: flex;
              width: 50%;
              justify-content: center;
              align-items: center;

              input {
                margin-left: 10px;
              }
            }
          }

          .revenue-and-expenses {
            display: flex;
            flex-direction: column;

            .revenue,
            .expenses {
              width: 100%;
              padding: 0 10px;

              h3 {
                margin-bottom: 10px;
              }

              .block {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 10px;

                .input-row {
                  display: flex;
                  align-items: center;
                  margin-bottom: 10px;
                  width: 30%;

                  label {
                    width: 40%;
                    font-size: 13px;
                    text-align: left;
                  }

                  .input-with-dollar {
                    display: flex;
                    align-items: center; // Align items vertically

                    span {
                      margin-right: 5px; // Add some space between the $ sign and the input field
                    }

                    input[type='text'] {
                      flex: 1;
                      width: 100%;
                      padding: 5px;
                      font-size: 13px;
                      border: 1px solid var(--lightgray-color);
                      border-radius: 8px;
                      box-sizing: border-box;
                      margin-right: 20px;
                    }
                  }
                }
              }
            }

            .total-section {
              margin-top: 20px;

              .total-row {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
              }

              .total-value {
                font-weight: bold;
              }

              .net-profit-loss1 {
                display: flex;
                justify-content: space-between;
                color: black;

                &.negative {
                  color: red;
                }

                &.positive {
                  color: green;
                }
              }
            }
          }

          .button {
            display: flex;
            justify-content: center;
            width: 100%;

            button[type='submit'] {
              display: block;
              padding: 10px 5px;
              margin-top: 20px;
              background-color: var(--secondary-bg-color);
              color: #fff;
              border: none;
              border-radius: 8px;
              cursor: pointer;
              font-size: 13px;
              width: 6rem;

              &:hover {
                background-color: var(--black-color);
              }
            }
          }
        }
      }
    }
  }
}

.modal.show {
  display: block;
  /* Show the modal when the "isOpen" prop is true */
}
