.cisForm-Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;

  .modal-container {
    width: 90%;
    height: 90%;
    background-color: var(--primary-bg-color);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow: scroll;

    h2 {
      text-align: center;
      margin-bottom: 20px;
      font-size: 1.8rem;
      color: #333;
    }

    .form-field {
      margin-bottom: 16px;

      .MuiTextField-root {
        width: 100%;
      }

      .Mui-error {
        color: red;
        font-size: 0.875rem;
      }
    }

    .form-actions {
      display: flex;
      justify-content: flex-end;
      gap: 8px;

      .submit-btn,
      .cancel-btn {
        min-width: 100px;
      }
    }

    form {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      .form-field {
        flex: 1 1 calc(50% - 16px); // Two fields per row with gap
        display: flex;
        flex-direction: column;

        label {
          font-weight: bold;
          margin-bottom: 8px;
        }

        input,
        select {
          width: 100%;
          font-size: 1rem;
          border-radius: 8px;

          &:focus {
            border-color: #1976d2;
            outline: none;
          }
        }

        .MuiTextField-root,
        .MuiSelect-root {
          width: 100%;
        }

        .MuiFormHelperText-root {
          font-size: 0.85rem;
          color: #777;
        }
      }

      .form-field-full {
        flex: 1 1 100%; // Full-width field
      }

      .form-actions {
        flex: 1 1 100%; // Full-width for buttons
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        .submit-btn {
          background-color: var(--secondary-bg-color);
          color: #fff;

          &:hover {
            background-color: #0056b3;
          }
        }

        .cancel-btn {
          background-color: #ccc;
          color: #333;

          &:hover {
            background-color: #999;
          }
        }
      }
    }

    @media screen and (max-width: 768px) {
      form {
        .form-field {
          flex: 1 1 100%; // Stack fields in one column on small screens
        }

        .form-actions {
          flex-direction: column;
          gap: 10px;

          button {
            width: 100%;
          }
        }
      }
    }

    @media screen and (max-width: 480px) {
      padding: 15px;

      h2 {
        font-size: 1.5rem;
      }

      form {
        gap: 12px;

        .form-actions {
          gap: 8px;
        }
      }
    }
  }
}
