.edit-User-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;

  .edit-Usermodal-content {
    display: flex;
    flex-direction: column;
    background: var(--primary-bg-color);
    border-radius: 8px;
    width: 50%;
    height: 50%;
    left: 100px;
    justify-content: center;
    min-width: 400px;

    .edit-User-top {
      display: flex;
      flex-direction: column;
      justify-content: center !important;
      align-items: center;
      width: 100%;
      gap: 20px;

      /* @media screen and (max-width: 1200px) {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
            } */

      .edit-User-block {
        display: flex;
        width: 100%;
        flex-direction: column;
        background-color: var(--lightgray-color);
        border-radius: 8px;
        gap: 30px;
        padding: 20px;

        form {
          display: flex;
          flex-wrap: wrap;
          padding: 20px;

          @media screen and (max-width: 768px) {
            display: flex;
            flex-wrap: wrap;
          }

          .select {
            width: 100%;
            padding: 2px 7px;
            background: var(--primary-bg-color);
            border-radius: 25px;
            border: none;
            outline: none;
          }

          /* .forminput-select {
                        width: 80%;

                        padding: 10px;

                        font-size: 12px;

                        border: 1px solid #ccc;

                        border-radius: 10px;

                        background-color: #fff;

                        color: #333;

                        outline: none;
                    } */

          .forminput-select::after {
            /*  content: '▼'; */
            /* Add a custom arrow */
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            pointer-events: none;
            /* Make sure the arrow doesn't interfere with clicks */
          }

          .forminput {
            display: flex;
            flex-direction: column;
            width: 50%;
            padding: 10px;

            label {
              display: flex;
              align-items: center;
              font-size: 12px;
              width: 20%;
              padding: 7px;
            }

            input {
              width: 100%;
              padding: 10px;
              border: 1px solid var(--lightgray-color);
              border-radius: 25px;
            }
          }

          .button {
            display: flex;
            margin-top: 40px;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-right: 40px;

            button {
              color: var(--primary-bg-color);
              font-size: 13px;
              padding: 10px 0px;
              margin-right: 5px;
              border-radius: 25px;
              display: inline-block;
              border: 0;
              outline: 0;
              background-color: var(--secondary-bg-color);
              text-decoration: none;
              width: 6rem;
              text-align: center;

              &:hover {
                background-color: var(--black-color);
              }
            }
          }
        }
      }
    }
  }
}
