.ring-1 {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    padding: 10px;
    border: 10px dashed var(--secondary-bg-color);;
    border-radius: 100%;
  }
  
  .load-4 {
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .ring-1 {
      animation: loadingD 1.5s 0s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
    }
  }
  
  @keyframes loadingD {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  