.navbar {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 14px;
  background-color: var(--secondary-bg-color);
  border-radius: 8px;
  border: none;

  .wrapper {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 20px;

    @media screen and (max-width: 768px) {
      padding: 15px 15px 15px 20px;
    }

    .title-nav {
      width: 100%;
      display: flex;
      align-items: center;

      h2 {
        color: var(--primary-bg-color);
        @media (max-width: 768px) {
          font-weight: 600;
          margin-top: 2px;
          font-size: 16px;
        }
      }
    }

    .items {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 15px;

      .item {
        display: flex;
        align-items: center;
        position: relative;
        text-transform: capitalize;

        span {
          color: var(--primary-bg-color);
          text-align: right;
          line-height: 18px;
        }

        .employee-avatar {
          position: relative;
          cursor: pointer;
        }

        .icon {
          font-size: 20px;
        }

        .employee-avatar.hovered img {
          transform: scale(1.2);
          /* Adjust the scale factor for your desired effect */
        }

        .avatar {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          cursor: pointer;
        }

        .options {
          position: absolute;
          top: 100%;
          right: -100%;
          background-color: var(--primary-bg-color);

          display: flex;
          flex-direction: column;
          z-index: 1;
          border-radius: 8px;
          margin-top: 10px;
          padding: 10px;
          width: 150px;

          .links-icon {
            text-decoration: none;
            color: var(--secondary-bg-color);
            cursor: pointer;
          }
        }

        button {
          padding: 10px;
          border: none;

          background-color: transparent;
          color: var(--secondary-bg-color);
          cursor: pointer;
          text-align: left;
          text-decoration: none;
          font-weight: 500;
        }

        button:hover {
          background-color: var(--lightgray-color);
          border-radius: 8px;
        }
      }
    }
  }
}
