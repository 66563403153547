.hi-main {
  display: flex;

  .admin-sidebar {
    width: 210px;
    transition: transform 0.3s ease-in-out;
    transform: translateX(0);
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;

    z-index: 10;

    @media (max-width: 768px) {
      transform: translateX(-100%);
    }

    &.visible {
      transform: translateX(0);
    }

    .close-btn {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 24px;
      color: var(--primary-bg-color);
      cursor: pointer;

      @media (min-width: 769px) {
        display: none;
      }
    }
  }

  .hi-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    // @media (min-width: 768px) {
    //   margin-left: 220px;
    //   max-width: calc(100% - 220px);
    // }

    .navbarcontainer {
      left: 220px;
      right: 2px;
      position: fixed;
      z-index: 9;
      top: 0px;
      background: var(--primary-bg-color);
      border-radius: 0px 0px 25px 25px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;

      @media (max-width: 768px) {
        left: 10px;
        right: 18px;
        justify-content: flex-start;
        /* Align items to the start on smaller screens */
        padding: 0 10px;
      }

      .hamburger-btn {
        display: none;
        background: none;
        border: none;
        font-size: 24px;
        color: var(--primary-bg-color);
        cursor: pointer;

        @media (max-width: 768px) {
          display: block;
          position: absolute;
          top: 35px;
          left: 20px;
        }
      }
    }

    .hi-block-container {
      width: 100%;
      display: flex;
      flex-direction: column;

      .filter-menu {
        display: flex;
        width: 100%;
        justify-content: center;
        gap: 10px;
        color: var(--black-color);
        background-color: var(--lightgray-color);
        border-radius: 8px;
        padding: 15px;
        align-items: center;
        flex-wrap: wrap;

        @media screen and (max-width: 768px) {
          justify-content: center;
          gap: 10px;
        }

        .filter-datemenu {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 7px;
        }

        @media (max-width: 768px) {
          flex-direction: column;
          justify-content: stretch;
        }

        label {
          margin-left: 20px;
          font-size: 13px;

          @media screen and (max-width: 768px) {
            margin-left: 0px;
            width: 25%;
          }
        }
        input {
          @media screen and (max-width: 768px) {
            width: 65%;
          }
        }

        .edit-btn,
        button {
          // padding: 10px 10px;
          border: none;
          border-radius: 8px;
          cursor: pointer;
        }
        .upload-btn {
          margin-left: auto;

          @media screen and (max-width: 930px) {
            margin-left: 0px;
          }
        }
      }

      .bot {
        width: 100%;
        margin-top: 20px;

        .tablecontainer {
          display: flex;
          flex-direction: column;
          width: 100%;

          /* .topbuttons {

                        display: flex;
                        justify-content: flex-start;

                        .edit-btn {
                            color: var(--primary-bg-color);
                            font-size: 13px;
                            padding: 10px 0px;
                            margin-right: 5px;
                            border-radius: 8px;
                            display: inline-block;
                            border: 0;
                            outline: 0;
                            background-color: var(--secondary-bg-color);
                            text-decoration: none;
                            width: 6rem;
                            text-align: center;

                            &:hover {
                                background-color: var(--black-color);
                            }
                        }
                    } */

          .bottomtable {
            display: flex;
            background-color: var(--lightgray-color);
            border-radius: 8px;
            width: 100%;
            padding: 20px;
          }
        }
      }

      /*  .upload-button-container {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 30px;

                .upload-button {
                    padding: 10px 20px;
                    font-size: 16px;
                    cursor: pointer;
                }
            } */
    }
  }
}
