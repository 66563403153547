.ec-main {
  display: flex;
  .message{
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }

  .admin-sidebar {
    width: 210px;
    transition: transform 0.3s ease-in-out;
    transform: translateX(0);
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;

    z-index: 10;

    @media (max-width: 768px) {
      transform: translateX(-100%);
    }

    &.visible {
      transform: translateX(0);
    }

    .close-btn {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 24px;
      color: var(--primary-bg-color);
      cursor: pointer;

      @media (min-width: 769px) {
        display: none;
      }
    }
  }

  .ec-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;


    @media (max-width: 768px) {
      margin-left: 0px;
    }

    .navbarcontainer {
      left: 220px;
      right: 2px;
      position: fixed;
      z-index: 9;
      top: 0px;
      background: var(--primary-bg-color);
      border-radius: 0px 0px 25px 25px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;

      @media (max-width: 768px) {
        left: 10px;
        right: 18px;
        justify-content: flex-start;
        /* Align items to the start on smaller screens */
        padding: 0 10px;
      }

      .hamburger-btn {
        display: none;
        background: none;
        border: none;
        font-size: 24px;
        color: var(--primary-bg-color);
        cursor: pointer;

        @media (max-width: 768px) {
          display: block;
          position: absolute;
          top: 35px;
          left: 20px;
        }
      }
    }

    /* .ec-block {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin-top: 90px;
            max-width: calc(100% - 220px);
         */

    .ec-block-container {
      width: 100%;
      display: flex;
      flex-direction: column;

      @media (max-width: 768px) {
        height: auto;
      }

      /* .loading-overlay {
                position: fixed;
                left: 239px;
                right: 18px;
                height: 100%;
                top: 200px;
                max-width: calc(100% - 220px);
                border-radius: 25px;
                background-color: rgba(0, 0, 0, 0.8); // Semi-transparent black overlay
                z-index: 9999; // Ensure it appears above other content
                display: flex;
                justify-content: center;
                align-items: center;
            } */

      .filter-menu-list {
        display: flex;
        width: 100%;
        gap: 10px;
        justify-content: center;
        color: var(--black-color);
        background-color: var(--lightgray-color);
        border-radius: 8px;
        padding: 15px;
        align-items: center;
        flex-wrap: wrap;

        @media screen and (max-width: 768px) {
          display: flex;
          flex-direction: column;
        }

        /* @media screen and (max-width: 1000px) {
                    display: flex;
                    flex-flow: wrap;
                    flex-direction: column;

                    .filter-menu {
                        margin: 5px;
                    }
                } */

        .filter-menu {
          display: flex;
          flex-wrap: wrap;
          text-align: center;

          // label {
          //   margin-right: 10px;
          //   font-size: 12px;
          //   margin-top: 10px;
          //   text-align: center;
          // }

          .labelFilter {
            margin-left: 20px;
            font-weight: bold;
            color: var(--secondary-bg-color);
            background-color: var(--primary-bg-color);
            padding-top: 7px;
            text-align: center;
            margin-top: 0px;
          }

          select {
            padding: 5px;
            border: none;
          }
        }

        .filter-datemenu {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 7px;
        }

        @media (max-width: 768px) {
          flex-direction: column;
          justify-content: stretch;
        }

        label {
          margin-left: 20px;
          font-size: 13px;

          @media screen and (max-width: 768px) {
            margin-left: 0px;
            width: 25%;
          }
        }
        input {
          @media screen and (max-width: 768px) {
            width: 65%;
          }
        }

        // .filter-datemenu {
        //   display: flex;
        //   width: 100%;

        //   @media screen and (max-width: 768px) {
        //     display: flex;
        //     flex-wrap: wrap;
        //   }

        //   label {
        //     margin-left: 10px;
        //   }

        //   .filter-datemenu-input {
        //     margin-left: 5px;
        //     padding: 5px;
        //     border: none;
        //   }

        //   .MuiSelect-root {
        //     width: 100%;
        //     padding: 10px;
        //     border-radius: 4px;
        //     font-size: 14px;
        //     background-color: #fff;
        //     border: 1px solid #ccc;
        //     transition: border-color 0.3s;

        //     &:focus {
        //       border-color: #007bff;
        //     }
        //   }

        //   .MuiIconButton-root {
        //     background-color: var(--secondary-bg-color);
        //     color: #fff;
        //     padding: 8px;
        //     transition: background-color 0.3s;

        //     &:hover {
        //       background-color: #0056b3;
        //     }
        //   }

        //   /* .filter-datemenu-button {
        //                 margin-left: 5px;
        //                 color: var(--primary-bg-color);
        //                 font-size: 14px;
        //                 padding: 10px 0px;
        //                 margin-right: 5px;
        //                 border-radius: 25px;
        //                 display: inline-block;
        //                 border: 0;
        //                 outline: 0;
        //                 background-color: var(--secondary-bg-color);
        //                 text-decoration: none;
        //                 width: 6rem;

        //                 &:hover {
        //                     background-color: var(--black-color);
        //                 }
        //             } */
        // }

        .upload-button-container {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: auto;

          @media screen and (max-width: 845px) {
            margin-left: 0px;
          }

          .upload-button {
            padding: 10px 20px;
            font-size: 16px;
            cursor: pointer;
          }

          .add-button {
            width: 100%;

            display: flex;
            justify-content: flex-end;

            button {
              margin-left: 5px;
              color: var(--primary-bg-color);
              font-size: 14px;
              padding: 10px 0px;
              margin-right: 5px;
              border-radius: 8px;
              display: inline-block;
              border: 0;
              outline: 0;
              background-color: var(--secondary-bg-color);
              text-decoration: none;
              width: 6rem;

              &:hover {
                background-color: var(--black-color);
              }
            }
          }
        }
      }

      .eventlist-container {
        display: flex;
        width: 100%;
        flex-direction: column;
        margin-top: 20px;
        gap: 20px;

        .eventlist-tile {
          display: flex;
          justify-content: space-between;
          border: 1px solid #ccc;
          border-radius: 8px;
          padding: 20px;
          width: 100%;
          font-size: 14px;

          @media screen and (max-width: 768px) {
            padding: 15px;
          }

          .titles {
            display: flex;
            flex-grow: 0.5;
            flex-direction: column;
            gap: 10px;

            div {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
          }

          .titles-value {
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: flex-end;
            flex-grow: 1;
            width: calc(33% - 5px);

            .value-name {
              text-align: right;
            }

            .reps-value {
              display: flex;
              flex-wrap: wrap;
              justify-content: flex-end;
              .rep-tag {
                padding: 5px 10px;
                display: inline-block;
                border-radius: 25px;
                font-size: 14px;
                @media screen and (max-width: 768px) {
                  font-size: 12px;
                }
              }
            }
          }

          .button-row {
            // max-width: 40%;
            width: calc(33% - 5px);
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;

            @media screen and (max-width: 768px) {
              display: flex;
              flex-wrap: wrap;
            }

            /*  button {
                            margin-left: 5px;
                            color: var(--primary-bg-color);
                            font-size: 14px;
                            padding: 10px 0px;
                            margin-right: 5px;
                            border-radius: 25px;
                            display: inline-block;
                            border: 0;
                            outline: 0;
                            background-color: var(--secondary-bg-color);
                            text-decoration: none;
                            width: 6rem;


                            &:hover {
                                background-color: var(--black-color);
                            }
                        } */
          }
        }
      }
    }

    /*  } */
  }
}
