.cvm-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;

  .modal-content {
    display: flex;
    flex-direction: column;
    background: var(--primary-bg-color) !important;
    border-radius: 25px;
    width: 60%;
    height: auto;
    padding: 20px;
    min-width: 400px;

    .close-button {
      display: flex;
      justify-content: flex-end;

      button {
        background: none;
        border: none;
        font-size: 1rem;
        cursor: pointer;
        padding-right: 20px;
        padding-top: 7px;
      }
    }

    .tablecontainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 20px;

      .bottomtable {
        display: flex;
        border-radius: 25px;
        background-color: var(--lightgray-color);
        padding: 20px;
      }

      .topbuttons {
        margin-top: 20px;
        display: flex;
        gap: 10px;
        padding-bottom: 20px;

        .edit-btn {
          color: var(--primary-bg-color);
          font-size: 13px;
          padding: 10px 0px;
          margin-right: 5px;
          border-radius: 25px;
          display: inline-block;
          border: 0;
          outline: 0;
          background-color: var(--secondary-bg-color);
          text-decoration: none;
          width: 6rem;
          text-align: center;

          &:hover {
            background-color: var(--black-color);
          }
        }
      }
    }
  }
}
