.managerdashboard {
  display: flex;
  flex-direction: column;
  width: 100%;

  .section{
    display: flex;
    flex-direction: column;
    padding: 5px 10px 15px;
    border: 1px solid #00000052;
    border-radius: 10px;
    margin-top: 20px;
  }

  .yearDD {
    margin-top: 20px;
    width: 35%;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    label {
      font-size: 14px;
      font-weight: 600;
      margin-top: -10px;
      margin-bottom: 5px;
    }
    .year-select {
      width: 50%;
    }
  }

  h3 {
    font-weight: bold;
    padding: 10px;
    font-size: 13px;
    margin-top: 20px;
  }

  .filter-menu-list {
    display: flex;
    padding: 20px;
    color: var(--black-color);
    background-color: var(--lightgray-color);
    border-radius: 8px;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;

    @media screen and (max-width: 872px) {
      display: flex;
      flex-flow: wrap;
      flex-direction: column;
      align-items: center;

      .filter-menu {
        margin: 5px;
      }
    }

    .filter-menu {
      margin-right: 20px;
      display: flex;
      text-align: center;
      align-items: center;

      #storeslist{
        width: fit-content;
        margin: 0 5px;
      }

      .labelFilter {
        margin-left: 20px;
        font-size: 12px;
        font-weight: bold;
        color: var(--secondary-bg-color);
        background-color: var(--primary-bg-color);
        padding-top: 7px;
        text-align: center;
        margin-top: 0px;
      }

      select {
        padding: 5px;
        border: none;
      }
    }

    .filter-datemenu {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 7px;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: stretch;
    }

    label {
      margin-left: 20px;
      font-size: 13px;

      @media screen and (max-width: 768px) {
        margin-left: 0px;
        width: 25%;
      }
    }
    input {
      @media screen and (max-width: 768px) {
        width: 65%;
      }
    }
  }

  .widgetcontainer-top {
    display: flex;
    height: auto;
    width: 100%;
    flex-direction: column;
    gap: 20px;

    .widgetcontainer-top-left {
      display: flex;
      width: 100%;
      padding: 10px;
      background-color: var(--secondary-bg-color);
      border-radius: 8px;
      justify-content: space-evenly;

      @media screen and (max-width: 1020px) {
        display: flex;
        flex-flow: wrap;
      }

      .widgetcontainer-top-left-div {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;

        .top-div-p {
          display: flex;
          justify-content: center;

          p {
            font-size: 10px;
            color: var(--primary-bg-color);
          }
        }

        .top-div-span {
          display: flex;
          justify-content: center;

          span {
            font-size: 25px;
          }
        }

        .top-div-bot {
          display: flex;
          justify-content: center;
          gap: 0px;

          .target {
            display: flex;
            gap: 6px;
            flex-direction: column;
            padding: 6px;
            color: var(--primary-bg-color);

            p {
              font-size: 10px;
            }

            span {
              font-size: 11px;
              padding-left: 3px;
            }
          }
        }
      }
    }

    .widgetcontainer-top-right {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      .top-div-bot {
        display: flex;
        justify-content: space-between;
        width: 100%;

        @media screen and (max-width: 1320px) {
          display: flex;
          flex-wrap: wrap;
        }

        @media screen and (max-width: 800px) {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          gap: 10px;
        }

        .top-div-bot1 {
          display: flex;
          width: 45%;
          background-color: var(--secondary-bg-color);
          border-radius: 8px;
          height: 100px;
          flex-direction: column;

          @media screen and (max-width: 800px) {
            width: 100%;
          }

          .top-div-title {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            color: var(--primary-bg-color);
            padding-top: 5px;

            p {
              font-size: 12px;
              padding: 10px;
            }
          }

          .top-div-body {
            display: flex;
            width: 100%;

            justify-content: space-evenly;

            .target {
              display: flex;
              flex-direction: column;
              padding: 5px;
              color: var(--primary-bg-color);
              gap: 3px;

              p {
                font-size: 12px;
                padding: 5px;
              }

              span {
                font-size: 15px;
                padding-left: 22px;
              }
            }
          }
        }

        .top-div-bot2 {
          display: flex;
          width: 45%;
          background-color: var(--secondary-bg-color);
          border-radius: 8px;
          height: 100px;
          flex-direction: column;

          @media screen and (max-width: 800px) {
            width: 100%;
          }

          .top-div-title {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            color: var(--primary-bg-color);
            padding-top: 5px;

            p {
              font-size: 12px;
              padding: 10px;
            }
          }

          .top-div-body {
            display: flex;
            width: 100%;

            justify-content: space-evenly;

            .target {
              display: flex;
              flex-direction: column;
              padding: 5px;
              color: var(--primary-bg-color);
              align-items: center;
              gap: 3px;

              p {
                font-size: 12px;
                padding: 5px;
              }

              span {
                font-size: 15px;
                padding-left: 5px;
              }
            }
          }
        }
      }
    }
  }

  .bot-widgets {
    width: 100%;
    display: flex;
    gap: 20px;

    @media screen and (max-width: 800px) {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .widgetcontainer {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;

      .widget {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 15px;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background-color: var(--lightgray-color);

        .left,
        .right {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .title {
            font-weight: 500;
            font-size: 13px;
            margin-bottom: 7px;
          }

          .counter {
            font-size: 13px;
            font-weight: 500;
            padding-bottom: 10px;
          }

          .link {
            width: max-content;
            font-size: 12px;
            border-bottom: 1px solid gray;
          }

          .percentage {
            display: flex;
            align-items: center;
            font-size: 14px;

            &.positive {
              color: green;
            }

            &.negative {
              color: red;
            }
          }

          .icon {
            font-size: 18px;
            padding: 5px;
            border-radius: 5px;
            align-self: flex-end;
          }
        }
      }
    }
  }

  .tablecontainer {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
    padding-bottom: 20px;

    .bottomtable {
      display: flex;
      border-radius: 8px;
      background-color: var(--lightgray-color);
      padding: 20px;
    }

    .topbuttons {
      margin-top: 20px;
      display: flex;
      gap: 10px;
      padding-bottom: 20px;

      .edit-btn {
        color: var(--primary-bg-color);
        font-size: 13px;
        padding: 10px 0px;
        margin-right: 5px;
        border-radius: 8px;
        display: inline-block;
        border: 0;
        outline: 0;
        background-color: var(--secondary-bg-color);
        text-decoration: none;
        width: 6rem;
        text-align: center;

        &:hover {
          background-color: var(--black-color);
        }
      }
    }
  }

  .charts {
    width: 100%;
    margin-top: 20px;
    display: grid;
    gap: 20px;
    grid-template-columns: 2fr 2fr;

    @media screen and (max-width: 1100px) {
      grid-template-columns: 1fr;
    }

    .spinner-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 300px;
      /* Adjust based on your chart height */
      background-color: var(--lightgray-color);
      border-radius: 8px;
    }
  }
}
