.change-password {
  display: flex;

  .change-password-main {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;


    .navbarcontainer {
      left: 239px;
      right: 18px;
      position: fixed;
      z-index: 9;
      top: 0px;
      background: var(--primary-bg-color);
      border-radius: 0px 0px 25px 25px;
      border: none;
    }

    .change-password-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: var(--lightgray-color);
      border-radius: 25px;
      padding: 20px;
      width: 100%;

      h2 {
        text-align: center;
        color: var(--black-color);
        font-size: 16px;
      }

      form {
        display: flex;
        flex-direction: column;
        max-width: 500px;
        width: 100%;

        .form-group {
          width: 100%;

          label {
            display: block;
            font-size: 13px;
            margin: 8px 0;
            color: var(--black-color);
          }

          input {
            width: 100%;
            padding: 8px 12px 8px 12px;
            border-radius: 10px;
            outline: none;
            background: none;
          }
        }

        .submitbutton {
          margin-top: 20px;
          width: 100%;

          button {
            color: var(--primary-bg-color);
            font-size: 16px;
            padding: 10px 30px;
            border-radius: 25px;
            display: inline-block;
            border: 0;
            outline: 0;
            background-color: var(--secondary-bg-color);
            width: 100%;

            &:hover {
              background-color: var(--black-color);
            }
          }
        }

        .message {
          text-align: center;
          color: green;
          margin-top: 10px;
        }
      }
    }
  }
}
