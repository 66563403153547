.refund {
  display: flex;

  .admin-sidebar {
    width: 210px;
    transition: transform 0.3s ease-in-out;
    transform: translateX(0);
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;

    z-index: 10;

    @media (max-width: 768px) {
      transform: translateX(-100%);
    }

    &.visible {
      transform: translateX(0);
    }

    .close-btn {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 24px;
      color: var(--primary-bg-color);
      cursor: pointer;

      @media (min-width: 769px) {
        display: none;
      }
    }
  }

  .refund-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    // @media (min-width: 768px) {
    //   margin-left: 220px;
    //   max-width: calc(100% - 220px);
    // }

    .navbarcontainer {
      left: 220px;
      right: 2px;
      position: fixed;
      z-index: 9;
      top: 0px;
      background: var(--primary-bg-color);
      border-radius: 0px 0px 25px 25px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;

      @media (max-width: 768px) {
        left: 10px;
        right: 18px;
        justify-content: flex-start;
        /* Align items to the start on smaller screens */
        padding: 0 10px;
      }

      .hamburger-btn {
        display: none;
        background: none;
        border: none;
        font-size: 24px;
        color: var(--primary-bg-color);
        cursor: pointer;

        @media (max-width: 768px) {
          display: block;
          position: absolute;
          top: 35px;
          left: 20px;
        }
      }
    }

    /*  .refund-block {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin-top: 100px;
            max-width: calc(100% - 220px);
         */

    .refund-block-container {
      width: 100%;
      display: flex;
      flex-direction: column;

      @media (max-width: 768px) {
        height: auto;
      }

      .filter-menu {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        width: 100%;
        justify-content: center;
        color: var(--black-color);
        background-color: var(--lightgray-color);
        border-radius: 8px;
        padding: 15px;
        align-items: center;

        .file-input {
          display: none;
        }


        .upload-button {
          margin-left: auto;
          @media screen and (max-width: 930px) {
            margin-left: 0px;
          }
        }

        .filter-datemenu {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 7px;
        }

        @media (max-width: 768px) {
          flex-direction: column;
          justify-content: stretch;
        }

        label {
          margin-left: 20px;
          font-size: 13px;

          @media screen and (max-width: 768px) {
            margin-left: 0px;
            width: 25%;
          }
        }
        input {
          @media screen and (max-width: 768px) {
            width: 65%;
          }
        }
      }

      .top {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 25px;
        width: 100%;
        margin-top: 20px;
        background-color: var(--lightgray-color);
        border-radius: 8px;

        @media (max-width: 768px) {
          flex-direction: column;
          gap: 15px;
        }

        .refund-title-div {
          display: flex;
          justify-content: center;
          align-items: center;

          .refund-title {
            font-size: 20px;

            @media screen and (max-width: 768px) {
              font-size: 16px;
            }
          }
        }

        .refund-bottom-block {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 20px;

          .file-input {
            display: none;
          }

          .file-label {
            background-color: var(--secondary-bg-color);
            color: #fff;
            padding: 10px 0px;
            cursor: pointer;
            border-radius: 8px;
            font-size: 13px;
            width: 6rem;
            text-align: center;
          }

          .file-label:hover {
            background-color: var(--black-color);
          }
        }

        .bottom-button {
          display: flex;
          align-items: center;
          gap: 10px;

          .upload-button {
            background-color: var(--secondary-bg-color);
            color: #fff;
            padding: 10px 0px;
            border: none;
            border-radius: 8px;
            cursor: pointer;
            font-size: 13px;
            width: 6rem;
          }

          .upload-button:hover {
            background-color: var(--black-color);
          }
        }
      }

      .bot {
        width: 100%;
        margin-top: 20px;

        .tablecontainer {
          display: flex;
          flex-direction: column;
          width: 100%;

          .topbuttons {
            display: flex;
            justify-content: flex-end;
            // padding-bottom: 20px;

            .edit-btn {
              color: var(--primary-bg-color);
              font-size: 13px;
              padding: 10px 0px;
              margin-right: 5px;
              border-radius: 8px;
              display: inline-block;
              border: 0;
              outline: 0;
              background-color: var(--secondary-bg-color);
              text-decoration: none;
              width: 6rem;
              text-align: center;

              &:hover {
                background-color: var(--black-color);
              }
            }
          }

          .bottomtable {
            display: flex;
            background-color: var(--lightgray-color);
            border-radius: 8px;
            padding: 20px;
            width: 100%;
          }
        }
      }
    }

    /* } */
  }
}
