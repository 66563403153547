.br-main {
  display: flex;
  .admin-sidebar {
    width: 210px;
    transition: transform 0.3s ease-in-out;
    transform: translateX(0);
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;

    z-index: 10;

    @media (max-width: 768px) {
      transform: translateX(-100%);
    }

    &.visible {
      transform: translateX(0);
    }

    .close-btn {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 24px;
      color: var(--primary-bg-color);
      cursor: pointer;

      @media (min-width: 769px) {
        display: none;
      }
    }
  }

  .br-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;


    @media (max-width: 768px) {
      margin-left: 0px;
    }

    .navbarcontainer {
      left: 220px;
      right: 2px;
      position: fixed;
      z-index: 9;
      top: 0px;
      background: var(--primary-bg-color);
      border-radius: 0px 0px 25px 25px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;

      @media (max-width: 768px) {
        left: 10px;
        right: 18px;
        justify-content: flex-start;
        /* Align items to the start on smaller screens */
        padding: 0 10px;
      }

      .hamburger-btn {
        display: none;
        background: none;
        border: none;
        font-size: 24px;
        color: var(--primary-bg-color);
        cursor: pointer;

        @media (max-width: 768px) {
          display: block;
          position: absolute;
          top: 35px;
          left: 20px;
        }
      }
    }

    /* .br-block {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin-top: 90px;
            max-width: calc(100% - 220px);
        

            @media (max-width: 768px) {
                margin-left: 0px;
            }

            
        } */

    .br-block-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;

      .filter-menu-list {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        color: var(--black-color);
        background-color: var(--lightgray-color);
        border-radius: 8px;
        margin-bottom: 20px;
        width: 100%;
        text-align: center;

        @media screen and (max-width: 1200px) {
          display: flex;
          flex-flow: wrap;
          flex-direction: column;
          gap: 10px;

          .filter-menu {
            margin: 5px;
          }
        }

        .filter-menu {
          margin-right: 20px;
          display: flex;
          align-items: center;
          text-align: center;

          @media screen and (max-width: 786px) {
            flex-direction: column;
            gap: 10px;
          }

          label {
            font-size: 12px;
            text-align: center;
          }

          .labelFilter {
            margin-left: 20px;
            font-weight: bold;
            color: var(--secondary-bg-color);
            background-color: var(--primary-bg-color);
            padding-top: 7px;
            text-align: center;
            margin-top: 0px;
          }

          select {
            padding: 5px;
            border: none;
          }
          .filter-datemenu-button {
            margin-left: 15px;
          }
        }

        .filter-datemenu {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 7px;
        }

        @media (max-width: 768px) {
          flex-direction: column;
          justify-content: stretch;
        }

        label {
          margin-left: 20px;
          font-size: 13px;

          @media screen and (max-width: 768px) {
            margin-left: 0px;
            width: 25%;
          }
        }
        input {
          @media screen and (max-width: 768px) {
            width: 65%;
          }
        }

        .add-button {
          margin-left: auto;

          @media screen and (max-width: 1200px) {
            margin: auto;
          }
        }
      }

      .accountant-table {
        border-collapse: collapse;
        width: 100%;
        font-size: 12px;
        margin-top: 10px;

        th,
        td {
          border: 1px solid #ddd;
          padding: 3px;
          text-align: left;
        }

        th {
          background-color: #2dbade;
          width: 70%;
        }

        tr {
          font-weight: 400;
        }

        tr:hover {
          background-color: #ddd;
        }

        tbody tr:last-child {
          font-size: 12px;
          font-weight: bold;
          font-style: italic;
          background-color: #f2f2f2;
        }

        td:last-child {
          display: flex;
          justify-content: space-between;
        }
      }

      .net-profit-loss {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 10px;
        color: black;
        font-size: 15px;
        border: 1px solid #ddd;
        padding: 3px;
        text-align: left;
        background-color: #f2f2f2;
        font-weight: bold;

        &.negative {
          color: red;
        }

        &.positive {
          color: green;
        }

        .total-value {
          display: flex;
          justify-content: space-between;
          width: 30%;

          .left-span {
            padding-left: 5px;
          }
        }
      }
    }
  }
}
