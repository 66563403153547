.adduser {
  display: flex;
  width: 100%;
  height: 100vh;

  .admin-sidebar {
    width: 210px;
    transition: transform 0.3s ease-in-out;
    transform: translateX(0);
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;

    z-index: 10;

    @media (max-width: 768px) {
      transform: translateX(-100%);
    }

    &.visible {
      transform: translateX(0);
    }

    .close-btn {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 24px;
      color: var(--primary-bg-color);
      cursor: pointer;

      @media (min-width: 769px) {
        display: none;
      }
    }
  }

  .addusercontainer {
    width: 100%;
    display: flex;
    flex-direction: column;


    @media (max-width: 768px) {
      margin-left: 0px;
    }

    .navbarcontainer {
      left: 225px;
      right: 18px;
      position: fixed;
      z-index: 9;
      top: 0px;
      background: var(--primary-bg-color);
      border-radius: 0px 0px 25px 25px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;

      @media (max-width: 768px) {
        left: 10px;
        right: 18px;
        justify-content: flex-start;
        /* Align items to the start on smaller screens */
        padding: 0 10px;
      }

      .hamburger-btn {
        display: none;
        background: none;
        border: none;
        font-size: 24px;
        color: var(--primary-bg-color);
        cursor: pointer;

        @media (max-width: 768px) {
          display: block;
          position: absolute;
          top: 35px;
          left: 20px;
        }
      }
    }

    .container {
      display: flex;
      width: 100%;

      @media (max-width: 768px) {
        height: auto;
      }

      .bottom {
        background-color: var(--lightgray-color);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        width: 100%;

        form {
          display: flex;
          flex-wrap: wrap;
          padding: 20px;

          .select {
            width: 100%;
            background: var(--primary-bg-color);
            border-radius: 8px;
            border: none;
            outline: none;
          }

          .forminput-select::after {
            /*  content: '▼'; */
            /* Add a custom arrow */
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            pointer-events: none;
            /* Make sure the arrow doesn't interfere with clicks */
          }

          .forminput {
            display: flex;
            flex-direction: column;
            width: 50%;
            padding: 10px;

            p, label {
              display: flex;
              align-items: center;
              font-size: 12px;
              width: 20%;
              padding: 7px;
            }

            input {
              width: 100%;
              padding: 10px;
              border: 1px solid var(--lightgray-color);
              border-radius: 8px;
            }
          }

          .button {
            display: flex;
            margin-top: 40px;
            align-items: center;
            justify-content: center;
            width: 100%;
            gap: 50px;

            button {
              color: var(--primary-bg-color);
              font-size: 13px;
              padding: 10px 0px;
              margin-right: 5px;
              border-radius: 8px;
              display: inline-block;
              border: 0;
              outline: 0;
              background-color: var(--secondary-bg-color);
              text-decoration: none;
              width: 6rem;
              text-align: center;

              &:hover {
                background-color: var(--black-color);
              }
            }
          }
        }
      }
    }
  }
}
