.manual {
  display: flex;
  scroll-behavior: smooth;

  .admin-sidebar {
    width: 210px;
    transition: transform 0.3s ease-in-out;
    transform: translateX(0);
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;

    z-index: 10;

    @media (max-width: 768px) {
      transform: translateX(-100%);
    }

    &.visible {
      transform: translateX(0);
    }

    .close-btn {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 24px;
      color: var(--primary-bg-color);
      cursor: pointer;

      @media (min-width: 769px) {
        display: none;
      }
    }
  }

  .manual-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;


    @media (max-width: 768px) {
      margin-left: 0px;
    }

    .navbarcontainer {
      left: 225px;
      right: 18px;
      position: fixed;
      z-index: 9;
      top: 0px;
      background: var(--primary-bg-color);
      border-radius: 0px 0px 25px 25px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 10px;
      padding-right: 2px;

      @media (max-width: 768px) {
        left: 10px;
        right: 18px;
        justify-content: flex-start;
        /* Align items to the start on smaller screens */
        padding-left: 10px;
        padding-right: 2px;
      }

      .hamburger-btn {
        display: none;
        background: none;
        border: none;
        font-size: 24px;
        color: var(--primary-bg-color);
        cursor: pointer;

        @media (max-width: 768px) {
          display: block;
          position: absolute;
          top: 35px;
          left: 20px;
        }
      }
    }

    .manual-block {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;

      .manual-block-container {
        display: flex;
        flex-direction: column;
        width: 100%;

        .bot {
          display: flex;
          width: 100%;
          flex-direction: column;

          .top-bar {
            width: 100%;
            display: flex;
            @media screen and (max-width:786px) {
              flex-wrap: wrap;
            }

            .upload-button-container {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;

              color: var(--secondary-bg-color);

              .upload-button {
                padding: 10px 20px;
                font-size: 16px;
                cursor: pointer;
              }
            }

            .search {
              width: 100%;
              position: relative;
              margin: 0 auto;
              background-color: var(--lightgray-color);
              border-radius: 25px;
              display: flex;
              justify-content: center;
              align-items: center;

              .search-input {
                width: 100%;
                padding: 10px;
                font-size: 16px;
                box-sizing: border-box;
                text-align: center;
                color: var(--secondary-bg-color);
              }
            }

            .clear-button {
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
              border: none;
              background: none;
              font-size: 20px;
              cursor: pointer;
              color: #aaa;
            }
          }

          .table {
            margin-top: 20px;
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: 20px;
            max-height: 80vh;
            overflow: auto;
            background-color: var(--lightgray-color);
            /*  border-radius: 25px; */
            /* padding: 20px; */

            thead {
              position: sticky;
              top: 0;
              background-color: var(--secondary-bg-color);
              color: var(--lightgray-color);
              z-index: 1;

              th {
                text-align: center;
                font-weight: bold;
              }
            }

            .professional-table {
              width: 100%;
              border-collapse: collapse;
              font-size: 12px;
              table-layout: fixed;

              th,
              td {
                padding: 10px;
                border: 1px solid var(--secondary-bg-color);
              }

              tbody {
                tr {
                  td {
                    text-align: center;
                  }
                }
              }
            }
          }

          .loading-overlay {
            margin-top: 20px;
            height: 60vh;
            max-width: 100%;
            border-radius: 25px;
            background-color: rgba(0, 0, 0, 0.8); // Semi-transparent black overlay
            z-index: 9999; // Ensure it appears above other content
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .top {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            padding: 15px;
            width: 100%;
            background-color: var(--lightgray-color);
            border-radius: 25px;

            .manual-title-div {
              display: flex;
              justify-content: center;
              align-items: center;

              .manual-title {
                font-size: 20px;
              }
            }

            .uploadFile {
              display: flex;
              flex-direction: column;
              align-items: center;

              .file-input {
                display: none;
              }

              .file-label {
                background-color: var(--secondary-bg-color);
                color: #fff;
                padding: 10px 0px;
                cursor: pointer;
                border-radius: 25px;
                font-size: 13px;
                width: 4rem;
                text-align: center;
              }

              .file-label:hover {
                background-color: var(--black-color);
              }
            }

            .bottom-button {
              display: flex;
              align-items: center;

              .upload-button {
                background-color: var(--secondary-bg-color);
                color: #fff;
                padding: 10px 0px;
                border: none;
                border-radius: 25px;
                cursor: pointer;
                font-size: 13px;
                width: 6rem;
              }

              .upload-button:hover {
                background-color: var(--black-color);
              }
            }
          }
        }
      }
    }
  }
}
