.var-main {
  display: flex;
  height: 100vh;
  background: var(--primary-bg-color);
  .message {
    text-align: center;
    color: black;
    font-size: 14px;
    margin-top: 30px;
  }

  .admin-sidebar {
    width: 210px;
    transition: transform 0.3s ease-in-out;
    transform: translateX(0);
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;

    z-index: 10;

    @media (max-width: 768px) {
      transform: translateX(-100%);
    }

    &.visible {
      transform: translateX(0);
    }

    .close-btn {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 24px;
      color: var(--primary-bg-color);
      cursor: pointer;

      @media (min-width: 769px) {
        display: none;
      }
    }
  }

  .var-container {
    width: 100%;
    display: flex;
    flex-direction: column;


    @media (max-width: 768px) {
      margin-left: 0px;
    }

    .navbarcontainer {
      left: 225px;
      right: 18px;
      position: fixed;
      z-index: 9;
      top: 0px;
      background: var(--primary-bg-color);
      border-radius: 0px 0px 25px 25px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;

      @media (max-width: 768px) {
        left: 10px;
        right: 18px;
        justify-content: flex-start;
        /* Align items to the start on smaller screens */
        padding: 0 10px;
      }

      .hamburger-btn {
        display: none;
        background: none;
        border: none;
        font-size: 24px;
        color: var(--primary-bg-color);
        cursor: pointer;

        @media (max-width: 768px) {
          display: block;
          position: absolute;
          top: 35px;
          left: 20px;
        }
      }
    }

    /*  .var-block {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin-top: 90px;
            max-width: calc(100% - 220px);
         */

    .var-block-container {
      display: flex;
      flex-direction: column;

      @media (max-width: 768px) {
        height: auto;
      }

      .filter-menu-list {
        display: flex;
        color: var(--black-color);
        border-radius: 25px;
        width: 100%;
        text-align: center;
        gap: 50px;

        @media screen and (max-width: 1200px) {
          flex-direction: column;
          gap: 20px;
        }

        .topbuttons {
          display: flex;
          height: 50px;
          align-items: center;
          justify-content: center;
          align-self: center;
        }
        .filter-menu-employee{
          padding: 10px;
          width: calc(50% + 5px);
          border-radius: 8px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

          @media screen and (max-width: 1200px) {
            width: 100%;
          }

        }

        .filter-menu {
          display: flex;
          align-items: center;
          gap: 20px;
          padding: 10px;
          border-radius: 8px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          width: calc(50% - 15px);

          @media screen and (max-width: 1200px) {
            width: 100%;
          }

          @media screen and (max-width: 768px) {
            flex-direction: column;
          }

          .filter-employee {
            width: 100%;
          }

          .filter-datemenu {
            display: flex;
            align-items: center;

            @media screen and (max-width: 660px) {
              display: flex;
              flex-direction: column;
              width: 100%;

              .filter-datemenu-select {
                width: 100%;
                margin: 10px 0;
              }

              label {
                width: 100%;
              }
            }

            p,
            label {
              margin-right: 10px;
              font-size: 13px;
              font-weight: bold;
            }

            .filter-datemenu-input {
              width: 100%;
              margin-right: 10px;
              /*   padding: 8px; */
              border: 1px solid #ccc;
              border-radius: 4px;
              font-size: 14px;
              background-color: #fff;
              transition: border-color 0.3s;

              &:focus {
                border-color: #007bff;
              }
            }

            .MuiSelect-root {
              width: 100%;
              padding: 10px;
              border-radius: 8px;
              font-size: 12px;
              background-color: #fff;
              border: 1px solid #ccc;
              transition: border-color 0.3s;

              &:last-child {
                margin-left: 10px;
              }

              &:focus {
                border-color: #007bff;
              }
            }

            .MuiIconButton-root {
              background-color: var(--secondary-bg-color);
              color: #fff;
              padding: 8px;
              transition: background-color 0.3s;

              &:hover {
                background-color: #0056b3;
              }
            }
          }
        }
      }

      .loading-overlay {
        margin-top: 20px;
        height: 60vh;
        max-width: 100%;
        border-radius: 25px;
        background-color: rgba(0, 0, 0, 0.8); // Semi-transparent black overlay
        z-index: 9999; // Ensure it appears above other content
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .tables {
        display: flex;
        flex-direction: column;
        max-height: 74vh;
        overflow: auto;
        margin-top: 20px;
        /* overflow: scroll; */

        .tables-container {
          display: flex;
          gap: 50px;

          @media (max-width: 768px) {
            display: flex;
            width: 100%;
            flex-direction: column;
          }

          .table {
            display: flex;
            width: 100%;
            flex-direction: column;
            margin-top: 10px;
            border-radius: 8px;
            max-height: 70vh;
            overflow: scroll;

            .table-container {
              width: 100%;
              display: flex;
              flex-direction: column;

              @media screen and (max-width: 660px) {
                display: flex;
                flex-direction: column;
              }

              .emp {
                border: 1px solid var(--secondary-bg-color);
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                padding: 15px;

                span {
                  font-weight: bold;
                }
              }

              .table-acc {
                display: flex;
                flex-direction: column;
                width: 100%;

                span {
                  font-style: bold;
                }

                .professional-table {
                  width: 100%;
                  border-collapse: collapse;
                  font-size: 12px;
                  table-layout: fixed;
                  height: 73vh;

                  th,
                  td {
                    padding: 10px;
                    border: 1px solid var(--secondary-bg-color);
                  }

                  thead {
                    background-color: var(--secondary-bg-color);
                    color: var(--lightgray-color);

                    th {
                      text-align: center;
                      font-weight: bold;
                    }
                  }

                  tbody {
                    tr {
                      td {
                        text-align: center;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    /* } */
  }
}
