.ar-main {
  display: flex;
  .message{
    margin-top: 30px;
  }

  .date-selection{
    display: flex;
    justify-content: space-between;
    align-items: center !important;
    flex: 1;
    max-width: 27%;
    @media screen and (max-width: 1200px) {
      max-width: 100%;
      flex-direction: column;
      gap: 10px;
    }
    label {
      font-size: 13px;
      font-weight: bold;
    }
  }

  input[type="month"] {
    width: 200px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #00000062;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s ease-in-out;
  }



  .admin-sidebar {
    width: 210px;
    transition: transform 0.3s ease-in-out;
    transform: translateX(0);
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;

    z-index: 10;

    @media (max-width: 768px) {
      transform: translateX(-100%);
    }

    &.visible {
      transform: translateX(0);
    }

    .close-btn {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 24px;
      color: var(--primary-bg-color);
      cursor: pointer;

      @media (min-width: 769px) {
        display: none;
      }
    }
  }

  .ar-container {
    width: 100%;
    display: flex;
    flex-direction: column;


    @media (max-width: 768px) {
      margin-left: 0px;
    }

    .navbarcontainer {
      left: 225px;
      right: 18px;
      position: fixed;
      z-index: 9;
      top: 0px;
      background: var(--primary-bg-color);
      border-radius: 0px 0px 25px 25px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 10px;
      padding-right: 2px;

      @media (max-width: 768px) {
        left: 10px;
        right: 18px;
        justify-content: flex-start;
        /* Align items to the start on smaller screens */
        padding-left: 10px;
        padding-right: 2px;
      }

      .hamburger-btn {
        display: none;
        background: none;
        border: none;
        font-size: 24px;
        color: var(--primary-bg-color);
        cursor: pointer;

        @media (max-width: 768px) {
          display: block;
          position: absolute;
          top: 33px;
          left: 20px;
        }
      }
    }

    /*  .ar-block {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin-top: 90px;
            max-width: calc(100% - 220px);
         */

    .ar-block-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;

      .loading-overlay {
        margin-top: 20px;
        height: 60vh;
        max-width: 100%;
        border-radius: 25px;
        background-color: rgba(0, 0, 0, 0.8); // Semi-transparent black overlay
        z-index: 9999; // Ensure it appears above other content
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 762px) {
          max-width: 90%;
          left: 5%;
          height: 50%;
          top: 380px;
        }
      }

      .filter-menu-list {
        display: flex;
        color: var(--black-color);
        border-radius: 25px;
        width: 100%;
        text-align: center;
        gap: 40px;

        @media screen and (max-width: 1200px) {
          flex-direction: column;
        }

        .topbuttons {
          display: flex;
          height: 50px;
          align-items: center;
          justify-content: center;
          align-self: center;
        }

        .filter-menu {
          display: flex;
          align-items: flex-start;
          padding: 10px;
          border-radius: 8px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          flex: 1;

          .filter-datemenu {
            display: flex;
            align-items: center;
            width: 100%;

            @media screen and (max-width: 660px) {
              display: flex;
              flex-direction: column;
              width: 100%;

              .filter-datemenu-select {
                width: 100%;
                margin: 10px 0;
              }

              label {
                width: 100%;
              }
            }

            label {
              margin-right: 10px;
              font-size: 13px;
              font-weight: bold;
            }

            .MuiSelect-root {
              width: 100%;
              padding: 10px;
              border-radius: 8px;
              font-size: 12px;
              background-color: #fff;
              border: 1px solid #ccc;
              transition: border-color 0.3s;

              &:last-child {
                margin-left: 10px;
              }

              &:focus {
                border-color: #007bff;
              }
            }

            .MuiIconButton-root {
              background-color: var(--secondary-bg-color);
              color: #fff;
              padding: 8px;
              transition: background-color 0.3s;

              &:hover {
                background-color: #0056b3;
              }
            }
          }
        }
      }
    }

    .table {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 20px;
      max-height: 75vh;
      overflow: auto;
      border-radius: 8px;
      margin-top: 30px;

      .table-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        /* background-color: var(--lightgray-color); */

        .emp {
          /* border: 1px solid var(--secondary-bg-color); */
          border-right: 0px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;

          span {
            font-weight: bold;
            margin-bottom: 10px;
          }
        }

        .emp-table {
          display: flex;
          width: 100%;
          gap: 100px;

          @media screen and (max-width: 660px) {
            display: flex;
            flex-direction: column;
            gap: 0px
          }

          .table-act > p,
          .table-hup > p {
            background-color: var(--secondary-bg-color);
            color: var(--primary-bg-color);
            padding: 14px;
            font-size: 16px;
            font-weight: 600;
            border: 1px solid var(--black-color);
          }

          .table-act,
          .table-hup {
            display: flex;
            flex-direction: column;
            width: 100%;
            background-color: var(--secondary-bg-color);
            /* span {
                                font-style: bold;
                            } */

            .professional-table {
              display: flex;
              width: 100%;
              border-collapse: collapse;
              font-size: 12px;
              /*  table-layout: fixed; */

              thead,
              tbody {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                
              }

              th,
              td {
                display: block;
                padding: 10px;
                border: 1px solid black;
                text-align: left;
                width: 100%;
                height: 100%;
                
              }

              thead {
                background-color: var(--secondary-bg-color);
                color: var(--lightgray-color);

                th {
                  text-align: center;
                 // font-weight: bold;
                  height: 30px;
                  font-size: 14px;
                }
              }

              tbody {
                td {
                  padding: 10px;
                  border: 1px solid var(--secondary-bg-color);
                  text-align: center;
                  height: 30px;
                  font-size: 14px;
                  background-color: var(--primary-bg-color);
                }

                td.highlight {
                  background-color: yellow;
                }
              }

              tr {
                display: flex;
                flex-direction: column;
                width: 100%;
              }

              /* th,
                                td {
                                    padding: 10px;
                                    border: 1px solid var(--secondary-bg-color);
                                }

                                thead {
                                    background-color: var(--secondary-bg-color);
                                    color: var(--lightgray-color);

                                    th {
                                        text-align: center;
                                        font-weight: bold;
                                    }
                                }

                                tbody {
                                    tr {
                                        

                                        td {
                                            text-align: center;
                                        }
                                    }
                                } */
            }
          }
        }
      }
    }

    /*  } */
  }
}
