.list {
  display: flex;
  width: 100%;
  padding:20px 0px;
  height: 100%;
  overflow: hidden;

  .listContainer {
    width: 100%;
    display: flex;
    flex-direction: column;


    @media (max-width: 768px) {
      margin-left: 0px;
    }


    .bottomtable {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-radius: 25px;
      border-collapse: collapse;

      .filterContainer {
        display: flex;
        gap: 10px;
        margin-bottom: 20px;
        width: 50%;
        justify-content: center;
        align-items: center;
        align-self: center;

        .MuiTextField-root,
        .MuiSelect-root {
          flex: 1;
          height: 56px;
          margin: 0;
        }
      }

      @media only screen and (max-width: 768px),
        (min-device-width: 768px) and (max-device-width: 1024px) {
        flex-direction: column;
        border-radius: 25px;

        table,
        thead,
        tbody,
        th,
        td,
        tr {
          display: block;
        }

        thead tr {
          position: absolute;
          top: -9999px;
          left: -9999px;
        }

        tr {
          border-bottom: 6px solid var(--secondary-bg-color);
        }

        /* row values Name:  Nehal  */

        td {
          border: none;
          border-bottom: 4px solid var(--primary-bg-color);
          position: relative;
          padding-left: 50%;
          padding-top: 8px;
          padding-bottom: 8px;
        }

        td:before {
          position: absolute;
          left: 6px;
          width: 10%;
          padding-left: 10px;
          white-space: nowrap;
          font-weight: bold;
        }

        /* Add title to the fields*/

        td:nth-of-type(1):before {
          content: 'Name';
        }

        td:nth-of-type(2):before {
          content: 'Username';
        }

        td:nth-of-type(3):before {
          content: 'Password';
        }

        td:nth-of-type(4):before {
          content: 'Email';
        }

        td:nth-of-type(5):before {
          content: 'Role';
        }

        td:nth-of-type(6):before {
          content: 'Phone No.';
        }

        td:nth-of-type(7):before {
          content: 'Date of Birth';
        }

        td:nth-of-type(8):before {
          content: 'Stores';
        }

        td:nth-of-type(9):before {
          content: 'DOB';
        }

        td:nth-of-type(10):before {
          content: 'Actions';
        }
      }

      .userlist-form {
        background-color: var(--lightgray-color);

        td:last-child {
          display: flex;
          justify-content: space-evenly;
          margin-bottom: 30px;
        }

        table {
          width: 100%;
          font-size: 14px;
          table-layout: auto;
          overflow: auto;

          tbody,
          td {
            border: 1px solid var(--primary-bg-color);
          }

          th,
          td {
            text-align: center;
            font-size: 13px;
            border-bottom: 7px solid var(--lightgray-color);
          }

          th {
            position: sticky;
            top: 0;
            background-color: var(--secondary-bg-color);
            height: 60px;
            font-size: 16px;
            font-weight: 130;
            inset-block-start: 0;
            color: var(--primary-bg-color);
            border-bottom: 2px solid var(--black-color);
            border-left: 2px solid var(--black-color);
            border-right: 2px solid var(--black-color);
          }

          td {
            input,
            textarea {
              width: auto;
              display: inline-block;
              border-radius: 5px;
              justify-content: center;
              padding: 4px;
              margin: 2px;
              box-sizing: border-box;
            }

            input:focus {
              border: 4px solid var(--primary-bg-color);
            }

            .table-btn {
              padding: 5px 5px;
              color: var(--black-color);
              background-color: var(--lightgray-color);
              display: inline-block;
              border-radius: 5px;
              cursor: pointer;
              font-size: 1rem;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
