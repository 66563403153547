.adminsidebar {
  height: 100vh;
  overflow: auto;
  width: 250px;
  background-color: var(--secondary-bg-color);
  padding: 5px;
  z-index: 10;
  &::-webkit-scrollbar {
    display: none;
  }

  /* @media screen and (max-width: 570px) {
        display: none;
    } */
  @media screen and (max-width: 568px) {
    height: 100%;
    width: 250px;
  }

  .top {
    height: 100px;
    margin-bottom: 50px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo {


      img {
        width: 200px;
        //width: 100%;
        //text-align: center;
        // justify-content: center;
        //height: 100%;
        //  height: 45px;
      }
    }
  }

  .center {
    .accordion-section {
      border-bottom: 1px solid var(--primary-bg-color);
      transition: height 0.3s;
      overflow: hidden;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      &.open {
        height: auto;
      }

      .clicked {
        background-color: var(--black-color);
      }
    }

    .accordion-title {
      display: flex;
      justify-content: space-between;
      padding: 12px;
      cursor: pointer;
      background-color: inherit;
      margin: 10px 0;
      color: var(--primary-bg-color);
      font-size: 16px;
      text-decoration: none;
      border-radius: 10px;

      &:hover {
        transform: scale(1);
        transform-origin: right bottom;
        background-color: var(--black-color);
      }

      .left {
        display: flex;
        gap: 8px;
        justify-content: center;
        align-items: center;

        /*  svg {
                    width: 14px;

                }*/
      }

      .arrow-icon {
        //  border: solid rgb(255, 255, 255);
        // border-width: 0 4px 4px 0;
        display: inline-block;
        // padding: 1px;
        // margin: 2px;
        //transform: rotate(-45deg);

        &.open {
          //  transform: rotate(-135deg);
          //-webkit-transform: rotate(-135deg);
        }
      }
    }

    .accordion-links {
      padding: 10px;
    }

    .accordion-link {
      padding: 10px;
      font-size: 16px;

      a {
        text-decoration: none;
        color: var(--primary-bg-color);

        &:hover {
          text-decoration: underline;
          text-decoration-thickness: 5px;

          // color: var(--black-color);
        }
      }
    }
  }
}
