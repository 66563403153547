.ecr {
    display: flex;
    height: 100vh;
    padding: 20px;
    background: var(--primary-bg-color);

    .ecrContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
    

        .navContainer {
            left: 239px;
            right: 18px;
            position: fixed;
            z-index: 9;
            top: 0px;
            background: var(--primary-bg-color);
            border-radius: 0px 0px 25px 25px;
            border: none;

        }

        .ecrDash {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin-top: 100px;

            .ecrDash-container {
                display: flex;
                flex-direction: column;
                width: 100%;

                .filter-menu-list {
                    display: flex;
                    padding: 20px;
                    color: var(--black-color);
                    background-color: var(--lightgray-color);
                    margin-bottom: 20px;
                    border-radius: 25px;
                    width: 100%;

                    @media screen and (max-width: 1250px) {
                        display: flex;
                        flex-flow: wrap;

                        .filter-menu {
                            margin: 5px;
                        }
                    }

                    .filter-menu {
                        margin-right: 20px;
                        display: flex;

                        label {
                            margin-right: 10px;
                            font-size: 13px;
                            margin-top: 5px;
                        }
                    }

                    .filter-datemenu {
                        display: flex;
                        width: 100%;

                        @media screen and (max-width: 950px) {
                            display: flex;
                            flex-flow: wrap;

                        }

                        label {
                            margin-left: 10px;
                        }

                        .filter-datemenu-input {
                            margin-left: 5px;
                        }

                        .filter-datemenu-button {
                            margin-left: 5px;
                            color: var(--primary-bg-color);
                            font-size: 16px;
                            padding: 1px 5px;
                            margin-right: 5px;
                            border-radius: 5px;
                            display: inline-block;
                            border: 0;
                            outline: 0;
                            background-color: var(--secondary-bg-color);
                            text-decoration: none;


                            &:hover {
                                background-color: var(--black-color);
                            }
                        }
                    }
                }

                .bot-widgets {
                    width: 100%;
                    display: flex;
                    gap: 20px;

                    @media screen and (max-width: 750px) {
                        display: flex;
                        flex-direction: column;
                    }

                    .widgetcontainer {

                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        flex-direction: column;
                        width: 100%;

                        h3 {
                            font-weight: bold;
                            padding: 10px;
                            font-size: 13px;
                            margin-top: 20px
                        }

                        .widget {
                            display: flex;
                            justify-content: space-around;
                            flex-direction: column;
                            flex-wrap: wrap;
                            padding: 10px;
                            width: 100%;
                            height: 100%;
                            border-radius: 25px;
                            background-color: var(--lightgray-color);

                            .left,
                            .right {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;


                                .title {
                                    font-weight: 500;
                                    font-size: 12px;
                                    margin-bottom: 7px;
                                }

                                .counter {
                                    font-size: 13px;
                                    font-weight: 500;

                                }

                                .link {
                                    width: max-content;
                                    font-size: 12px;
                                    border-bottom: 1px solid gray;
                                }

                                .percentage {
                                    display: flex;
                                    align-items: center;
                                    font-size: 14px;

                                    &.positive {
                                        color: green;
                                    }

                                    &.negative {
                                        color: red;
                                    }
                                }

                                .icon {
                                    font-size: 18px;
                                    padding: 5px;
                                    border-radius: 5px;
                                    align-self: flex-end;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}