.es-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;

  .modal-content {
    display: flex;
    flex-direction: column;
    background: var(--primary-bg-color) !important;
    border-radius: 25px;
    width: 100%;
    height: auto;
    max-width: 500px;

    .close-button {
      display: flex;
      justify-content: flex-end;

      button {
        background: none;
        border: none;
        font-size: 1rem;
        cursor: pointer;
        padding-right: 20px;
        padding-top: 7px;
      }
    }

    .top {
      padding: 20px;
      color: var(--black-color);
      width: 100%;

      .form-container {
        position: relative;
        margin: 0 auto;
        padding: 20px;
        // border: 1px solid #ccc;
        border-radius: 10px;
        background-color: #fff;
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

        .event-form {
          display: flex;
          flex-direction: column;

          .form-group {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px;

            label {
              width: 30%;
              margin-right: 10px;
              font-weight: bold;
              display: flex;
              align-items: flex-start;
            }

            .form-input,
            .form-select {
              flex: 1;
              width: 100%;
              padding: 10px;
              box-sizing: border-box;
              border: 1px solid #ccc;
              border-radius: 5px;
              font-size: 1rem;
            }

            .rep-select-group {
              display: flex;

              .add-button {
                margin-left: 10px;
                padding: 10px 20px;
                background-color: var(--secondary-bg-color);
                color: #fff;
                border: none;
                border-radius: 5px;
                cursor: pointer;

                &:hover {
                  background-color: var(--black-color);
                }
              }
            }
          }

          .added-reps {
            margin-top: 10px;

            .rep-item {
              display: flex;
              justify-content: space-between;
              align-items: center;

              background-color: #e0e0e0;
              padding: 5px 10px;
              margin-bottom: 5px;
              border-radius: 4px;

              .remove-rep {
                cursor: pointer;
                color: red;
                font-weight: bold;
              }
            }
          }

          .submit-button {
            padding: 10px 20px;
            background-color: var(--secondary-bg-color);
            color: #fff;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 1rem;

            &:hover {
              background-color: var(--black-color);
            }
          }
        }
      }
    }
  }
}
