.btn {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.39998px;
  line-height: 24.5px;
  padding: 6px 16px;
  border-radius: 8px;
  border: 0;
  outline: 0;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.3s;
  height: fit-content;

  &.default-btn {
    background-color: var(--secondary-bg-color);
  }

  &.Search-btn {
    background-color: rgb(25, 118, 210);
    font-weight: 700;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
      rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  }

  &.Upload-btn {
    background-color: #108800;
    font-weight: 700;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
      rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  }

  &.Download-btn {
    background-color: #108800;
    font-weight: 700;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
      rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  } 

  &.Delete-btn {
    background-color: rgb(211, 47, 47);
    font-weight: 700;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
      rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  }

  &.pill-btn {
    border-radius: 25px;
    padding: 10px 0px;
    width: 6rem;
    font-size: 13px;
    line-height: normal;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
    pointer-events: none;
    cursor: not-allowed;
  }

  &:hover {
    background-color: var(--black-color);
  }

}
