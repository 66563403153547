.rp-main {
  display: flex;
  .message {
    margin-top: 30px;
  }

  .date-selection {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center !important;
    // width: 40% !important;
    @media screen and (max-width: 1000px) {
      max-width: 100%;
      flex-direction: column;
      gap: 10px;
    }
    label {
      font-size: 13px;
      font-weight: bold;
    }
  }
  input[type='month'] {
    width: 200px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #00000062;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s ease-in-out;
  }

  .admin-sidebar {
    width: 210px;
    transition: transform 0.3s ease-in-out;
    transform: translateX(0);
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;

    z-index: 10;

    @media (max-width: 768px) {
      transform: translateX(-100%);
    }

    &.visible {
      transform: translateX(0);
    }

    .close-btn {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 24px;
      color: var(--primary-bg-color);
      cursor: pointer;

      @media (min-width: 769px) {
        display: none;
      }
    }
  }

  .rp-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      margin-left: 0px;
    }

    .navbarcontainer {
      left: 225px;
      right: 18px;
      position: fixed;
      z-index: 9;
      top: 0px;
      background: var(--primary-bg-color);
      border-radius: 0px 0px 25px 25px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 10px;
      padding-right: 2px;

      @media (max-width: 768px) {
        left: 10px;
        right: 18px;
        justify-content: flex-start;
        /* Align items to the start on smaller screens */
        padding-left: 10px;
        padding-right: 2px;
      }

      .hamburger-btn {
        display: none;
        background: none;
        border: none;
        font-size: 24px;
        color: var(--primary-bg-color);
        cursor: pointer;

        @media (max-width: 768px) {
          display: block;
          position: absolute;
          top: 35px;
          left: 20px;
        }
      }
    }

    /* .rp-block {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin-top: 90px;
            max-width: calc(100% - 220px);
         */

    .rp-block-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;

      .filter-menu-list {
        display: flex;
        color: var(--black-color);
        border-radius: 25px;
        width: 100%;
        text-align: center;
        gap: 40px;

        @media screen and (max-width: 1200px) {
          gap: 20px;
          flex-direction: column;
        }

        .topbuttons {
          display: flex;
          height: 50px;
          align-items: center;
          justify-content: center;
          align-self: center;
        }

        .filter-menu {
          display: flex;
          align-items: center;
          padding: 10px;
          gap: 20px;
          border-radius: 8px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          width: 50%;

          @media screen and (max-width: 1200px) {
            width: 100%;
          }
          @media screen and (max-width: 786px) {
            flex-wrap: wrap;
            justify-content: center;
          }

          .filter-rank-by {
            width: 100%;

            @media screen and (max-width: 1200px) {
              width: 100%;
            }

            label {
              font-weight: bold;
            }
          }

          .filter-datemenu {
            display: flex;
            align-items: center;

            @media screen and (max-width: 660px) {
              display: flex;
              flex-direction: column;
              width: 100%;

              .filter-datemenu-select {
                width: 100%;
                margin: 10px 0;
              }

              label {
                width: 100%;
              }
            }

            label {
              margin-right: 10px;
              font-size: 13px;
              font-weight: bold;
            }

            .MuiSelect-root {
              width: 100%;
              padding: 10px;
              border-radius: 8px;
              font-size: 12px;
              background-color: #fff;
              border: 1px solid #ccc;
              transition: border-color 0.3s;

              &:last-child {
                margin-left: 10px;
              }

              &:focus {
                border-color: #007bff;
              }
            }

            .MuiIconButton-root {
              background-color: var(--secondary-bg-color);
              color: #fff;
              padding: 8px;
              transition: background-color 0.3s;

              &:hover {
                background-color: #0056b3;
              }
            }
          }
        }
      }

      .loading-overlay {
        margin-top: 20px;
        height: 60vh;
        max-width: 100%;
        border-radius: 25px;
        background-color: rgba(0, 0, 0, 0.8); // Semi-transparent black overlay
        z-index: 9999; // Ensure it appears above other content
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .rp-typeFilter {
        gap: 20px;
        margin: 10px;

        label {
          margin-right: 10px;
          font-size: 13px;
        }

        select {
          font-size: 15px;
          padding: 2px;
        }
      }

      .datatable {
        display: flex;
        width: 100%;
        gap: 40px;
        margin-top: 20px;

        @media screen and (max-width: 1000px) {
          display: flex;
          flex-direction: column;
        }

        .storeTable,
        .empTable {
          /* display: flex;
                        width: 100%;
                        color: var(--black-color);
                        background-color: var(--lightgray-color);
                        border-radius: 25px;
                        padding: 20px;
                        border-collapse: collapse; */
          display: flex;
          width: 100%;
          border-collapse: collapse;
          max-height: 75vh;
          overflow: auto;

          table {
            width: 100%;
            table-layout: auto;
            overflow: auto;
          }

          th,
          td {
            /*  border-bottom: 1px solid #dddddd;
                            text-align: left; */
            border-bottom: 1px solid var(--secondary-bg-color);
            text-align: left;
            padding: 10px;
          }

          thead {
            background-color: var(--secondary-bg-color);
            color: var(--lightgray-color);

            th {
              text-align: center;
              position: sticky;
              top: 0;
              background-color: var(--secondary-bg-color);
              height: 60px;
              font-size: 16px;
              font-weight: 130;
              inset-block-start: 0;
              z-index: 1;
              color: var(--primary-bg-color);
            }
          }

          /* th {
                            background-color: #f2f2f2;
                            font-weight: bold;
                            padding: 5px;
                        } */

          td:last-child {
            display: flex;
            justify-content: space-between;
            align-items: space-between;
            border: none;
          }

          tr:nth-child(even) {
            background-color: #f2f2f2;
          }

          tr:hover {
            background-color: #ddd;
          }
        }
      }

      /*     } */
    }
  }
}
